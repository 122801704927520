import Header from "../components/header.js"
import Footer from "../components/footer.js"
import Button from "../components/button.js"
import Selsect from "../components/select.js"
import { Box } from "@mui/material"
const Main = () =>{
 return(
    <div>
        <Header />
        <Content />
        <Footer />
    </div>
 )
}

const Content = () =>{
    return(
        <div>
            <Box sx={{ p:1, width : "50%" }}>
                <Box sx={{ p:1 }}>
                    <Button text={"button"} />
                </Box>
                <Box sx={{ p:1 }}>
                    <Selsect  />
                </Box>
            </Box>
        </div>
    )
}

export default Main;