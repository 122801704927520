import React, { useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
 } from "@mui/material";

const SelectComponent = () => {
    const [age, setAge] = useState('');

    const handleChange = (e) => {
        setAge(e.target.value); // 選択された値を設定
    };

    return (
        <FormControl fullWidth >
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChange}
            >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
            </Select>
        </FormControl>
    );
};

export default SelectComponent;
